<template>

<div class="intro">

	<div class="intro-text">

		<app-title :text="data.title" />

		<div class="intro-info" v-on:click="onInfoClick" />
		
		<div v-html="data.text"></div>

	</div>

	<div class="intro-buttons">

		<app-button theme="purple" :text="data.button" v-on:click="onButtonClick(1)" />

	</div>

</div>

</template>

<script>

export default {

	props: ['data'],

	methods: {

		onButtonClick: function(value) {

			this.$emit('answered', value)

		},

		onInfoClick: function() {

			this.$router.push({
				name: 'Questions.Info'
			})

		}

	}

}

</script>

<style scoped>

.intro {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 684px;
	padding: 40px 20px;
}

.filter-back {
	position: absolute;
	left: 10px;
	top: 0px;
	color: #fff;
	letter-spacing: 1px;
	text-transform: uppercase;
	font-size: 16px;
	cursor: pointer;
}

.filter-back:hover span {
	text-decoration: underline;
}

.intro-text {
	color: #fff;
	font-size: 18px;
	text-align: center;
	line-height: 22px;
	margin-bottom: 40px;
}

.intro-text >>> p {
	margin-bottom: 40px;
}

.intro-text >>> *:last-child {
	margin-bottom: 0px;
}

.is-mobile .intro-text {
	padding: 0px 10px;
}

.is-mobile .intro-text >>> p {
	margin-bottom: 20px;
}

.intro-buttons {
	display: flex;
	flex-direction: row;
}

.intro-buttons >>> .button {
	margin: 0px 10px;
}

.intro-info {
	width: 74px;
	height: 71px;
	background-image: url(~@/assets/intro.info.svg);
	background-size: 74px 71px;
	position: absolute;
	right: -100px;
	top: 0px;
	cursor: pointer;
}

.is-mobile .intro-info {
	position: relative;
	right: auto;
	top: auto;
	margin: 0px auto;
	margin-bottom: 20px;
}

</style>
